var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative _slot-wrapper" }, [
    _c("div", { staticClass: "text-center mb-4" }, [
      _c(
        "h2",
        {
          staticClass: "mb-1",
          staticStyle: { "font-size": "32px", "font-weight": "500" },
        },
        [_vm._v("Create new password")]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "_welcome-subtitle mb-0 diana-theme-text-secondary" },
        [
          _vm._v("Complete setting up your new password for "),
          _c("br"),
          _vm._v("\n      " + _vm._s(this.urlQuery["email"])),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "fieldset",
          { attrs: { disabled: _vm.status.isLoading } },
          [
            false
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.userEmail,
                      expression: "formData.userEmail",
                    },
                  ],
                  staticClass: "input-lg text-left",
                  attrs: {
                    type: "email",
                    autocomplete: "username",
                    autocapitalize: "none",
                    autocorrect: "off",
                    spellcheck: "off",
                    disabled: "disabled",
                    required: "required",
                    placeholder: "Email",
                  },
                  domProps: { value: _vm.formData.userEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "userEmail", $event.target.value)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "flex-fill position-relative" },
                [
                  this.passwordType === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.newPassword,
                            expression: "formData.newPassword",
                          },
                        ],
                        ref: "firstElement",
                        staticClass:
                          "input-lg password-strength mb-4 accessibility-element",
                        attrs: {
                          tabindex: "1",
                          id: "frmPassword",
                          autocomplete: "off",
                          required: "required",
                          placeholder: "Enter new password",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.formData.newPassword)
                            ? _vm._i(_vm.formData.newPassword, null) > -1
                            : _vm.formData.newPassword,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.formData.newPassword,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.formData,
                                    "newPassword",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.formData,
                                    "newPassword",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.formData, "newPassword", $$c)
                            }
                          },
                        },
                      })
                    : this.passwordType === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.newPassword,
                            expression: "formData.newPassword",
                          },
                        ],
                        ref: "firstElement",
                        staticClass:
                          "input-lg password-strength mb-4 accessibility-element",
                        attrs: {
                          tabindex: "1",
                          id: "frmPassword",
                          autocomplete: "off",
                          required: "required",
                          placeholder: "Enter new password",
                          type: "radio",
                        },
                        domProps: {
                          checked: _vm._q(_vm.formData.newPassword, null),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "newPassword", null)
                          },
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.newPassword,
                            expression: "formData.newPassword",
                          },
                        ],
                        ref: "firstElement",
                        staticClass:
                          "input-lg password-strength mb-4 accessibility-element",
                        attrs: {
                          tabindex: "1",
                          id: "frmPassword",
                          autocomplete: "off",
                          required: "required",
                          placeholder: "Enter new password",
                          type: this.passwordType,
                        },
                        domProps: { value: _vm.formData.newPassword },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formData,
                              "newPassword",
                              $event.target.value
                            )
                          },
                        },
                      }),
                  _vm._v(" "),
                  _c("PasswordStrength", {
                    attrs: { value: _vm.formData.newPassword },
                    on: { isValid: _vm.isPasswordValid },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn end-icon",
                      attrs: { type: "button" },
                      on: { click: _vm.toggle },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "18",
                            height: "14",
                            viewBox: "0 0 14 12",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            staticStyle: { transition: "0.2s" },
                            attrs: {
                              d: "M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z",
                              fill: _vm.fillColor,
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("AlertMessage", {
              staticClass: "mb-4 bg-danger",
              model: {
                value: _vm.status.errorMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "errorMessage", $$v)
                },
                expression: "status.errorMessage",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-primary btn-lg w-100 accessibility-element",
                style: { backgroundColor: _vm.$store.state.buttonColor },
                attrs: {
                  tabindex: this.isValid() ? 2 : -1,
                  type: "submit",
                  disabled: this.isValid() === false,
                },
              },
              [
                _vm.status.isLoading
                  ? _c("Spinner")
                  : _c("span", [_vm._v("Save password")]),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }