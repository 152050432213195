var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column _slot-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "w-100 text-center",
        staticStyle: { "max-width": "337px" },
      },
      [
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "h2",
            {
              staticClass: "mb-1",
              staticStyle: { "font-size": "32px", "font-weight": "500" },
            },
            [_vm._v("Verify email address")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "my-0 diana-theme-text-secondary" }, [
            _vm._v(
              "\n        To complete setting up your Rise-X account, please click the verification link we’ve sent\n        to:\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "my-0 diana-theme-text-secondary" }, [
            _vm._v(_vm._s(this.$store.state.verifyEmail.email)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mb-3",
            staticStyle: {
              "border-radius": "16px",
              "background-color": "#f8f8f8",
              width: "100%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "center",
              "text-align": "center",
              padding: "16px",
            },
          },
          [
            _c("p", { staticClass: "mb-0" }, [_vm._v("Didn’t get the email?")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm.resendCountdown > 0
                ? _c("span", [
                    _vm._v("Check your spam folder or try resending it in "),
                    _c("br"),
                    _vm._v(
                      "\n          " + _vm._s(_vm.resendCountdown) + " seconds."
                    ),
                  ])
                : _c("span", [
                    _vm._v("\n          Check your spam folder or\n          "),
                    _c(
                      "a",
                      {
                        staticClass: "accessibility-link",
                        style: {
                          color: _vm.$store.state.buttonColor,
                          cursor: "pointer",
                          textDecoration: "underline",
                          padding: 0,
                        },
                        attrs: { tabindex: "1" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleResend.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Resend")]
                    ),
                  ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.status.errorMessage
          ? _c("ErrorMessage", {
              staticClass: "mb-3",
              model: {
                value: _vm.status.errorMessage,
                callback: function ($$v) {
                  _vm.$set(_vm.status, "errorMessage", $$v)
                },
                expression: "status.errorMessage",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.$store.state.verifyEmail.retryUrl
          ? _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "d-inline-flex" }, [
                _c(
                  "a",
                  {
                    staticClass: "diana-theme-text-primary",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$store.commit(
                          "ovNavigation/navigate",
                          "login"
                        )
                      },
                    },
                  },
                  [_vm._v("Back to login")]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }