var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_overlay d-flex flex-column" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("div", { staticClass: "_content-wrapper" }, [
        _c(
          "div",
          { staticClass: "_content", style: _vm.cssOverlayVars },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }