<template>
  <div class="position-relative _slot-wrapper">
    <div class="form-container" style="max-width: 337px">
      <div v-if="status.isSuccess && !isSendLinkView">
        <div class="mb-3 text-center">
          <h2 class="mb-1" style="font-size: 32px; font-weight: 500">Recovery link sent</h2>
          <p class="my-0 diana-theme-text-secondary">
            To complete setting up your new password, please click the verification link we’ve sent
            to:
          </p>
          <p class="my-0 diana-theme-text-secondary">{{ $store.state.username }}</p>
        </div>
        <div
          class="mb-3"
          style="
            border-radius: 16px;
            background-color: #f8f8f8;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 16px;
          "
        >
          <p class="mb-0">Didn’t get the email?</p>
          <p class="mb-0">
            <span v-if="resendCountdown > 0"
              >Check your spam folder or try resending it in <br />
              {{ resendCountdown }} seconds.</span
            >
            <span v-else>
              Check your spam folder or
              <a
                tabindex="1"
                class="accessibility-link"
                @click.prevent="handleResend"
                :style="{
                  color: $store.state.buttonColor,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  padding: 0,
                }"
                >Resend</a
              >
            </span>
          </p>
        </div>
      </div>

      <div v-else>
        <div class="text-center mb-4">
          <h2 class="mb-1" style="font-size: 32px; font-weight: 500">Forgot password?</h2>
          <span class="_welcome-subtitle mb-0 diana-theme-text-secondary"
            >We’ll send a recovery link to</span
          >
        </div>

        <form @submit.prevent="submit">
          <fieldset :disabled="status.isLoading">
            <!-- email input -->
            <input
              ref="firstElement"
              tabindex="1"
              v-model="$store.state.username"
              class="_input input-lg mb-3 accessibility-element"
              placeholder="Email"
              disabled
            />

            <!-- error message -->
            <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />

            <!-- submit button -->
            <button
              tabindex="2"
              type="submit"
              class="btn-lg btn-primary w-100 mt-2 mb-3 accessibility-element"
              v-bind:style="{ backgroundColor: $store.state.buttonColor, borderRadius: '6px' }"
            >
              <Spinner v-if="status.isLoading" />
              <span v-else>Send a link</span>
            </button>

            <div class="text-center">
              <a
                tabindex="3"
                href=""
                class="diana-theme-text-primary accessibility-link"
                @click.prevent="$store.commit('ovNavigation/navigate', 'login')"
                >Back to login</a
              >
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import ErrorMessage from '@/components/ErorrMessage.vue';

export default {
  name: 'ForgotPassword',
  components: { ErrorMessage },
  data: () => ({
    status: {
      isLoading: false,
      isSuccess: false,
      errorMessage: '',
    },
    isSendLinkView: true,
    resendCountdown: 0,
    timerId: null,
  }),
  methods: {
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.isSuccess = false;
        this.status.errorMessage = '';

        const queryData = {
          returnUrl: window.location.href,
          userEmail: this.$store.state.username,
        };

        const payload = {
          url: `account/resetPassword?${querystring.encode(queryData)}`,
          method: 'POST',
          data: '',
        };

        await this.$request(payload);
        this.status.isSuccess = true;
        this.isSendLinkView = false;
      } catch (e) {
        console.error(e.message);
        this.status.errorMessage = 'An unknown error has occurred. Please try again.';
      } finally {
        this.status.isLoading = false;
      }
    },
    handleResend() {
      this.submit();
      this.startResendCountdown(60);
    },
    startResendCountdown(seconds) {
      this.resendCountdown = seconds;

      if (this.timerId) {
        clearInterval(this.timerId);
      }

      this.timerId = setInterval(() => {
        if (this.resendCountdown > 0) {
          this.resendCountdown--;
        } else {
          clearInterval(this.timerId);
          this.timerId = null;
        }
      }, 1000);
    },
    openHelpScreen() {
      // Small delay so users know what is happening
      setTimeout(() => {
        window.open(this.$store.state.helpUrl, '_blank', 'noopener noreferrer');
        this.clearMessages();
      }, 500);
    },
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
};
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  flex-direction: column;
  .back-arrow {
    order: -1;
    align-self: self-start;
  }
}
</style>
