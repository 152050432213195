<template>
  <div class="position-relative _slot-wrapper">
    <div class="text-center mb-4">
      <h2 class="mb-1" style="font-size: 32px; font-weight: 500">Create new password</h2>
      <span class="_welcome-subtitle mb-0 diana-theme-text-secondary"
        >Complete setting up your new password for <br />
        {{ this.urlQuery['email'] }}</span
      >
    </div>

    <form @submit.prevent="submit">
      <fieldset :disabled="status.isLoading">
        <input
          v-if="false"
          v-model="formData.userEmail"
          type="email"
          autocomplete="username"
          autocapitalize="none"
          autocorrect="off"
          spellcheck="off"
          disabled="disabled"
          class="input-lg text-left"
          required="required"
          placeholder="Email"
        />

        <div class="d-flex">
          <div class="flex-fill position-relative">
            <input
              ref="firstElement"
              tabindex="1"
              id="frmPassword"
              v-model="formData.newPassword"
              :type="this.passwordType"
              autocomplete="off"
              class="input-lg password-strength mb-4 accessibility-element"
              required="required"
              placeholder="Enter new password"
            />
            <PasswordStrength :value="formData.newPassword" v-on:isValid="isPasswordValid" />
            <button type="button" class="btn end-icon" @click="toggle">
              <svg
                width="18"
                height="14"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z"
                  :fill="fillColor"
                  style="transition: 0.2s"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- error message -->
        <AlertMessage v-model="status.errorMessage" class="mb-4 bg-danger" />

        <button
          :tabindex="this.isValid() ? 2 : -1"
          type="submit"
          class="btn-primary btn-lg w-100 accessibility-element"
          :style="{ backgroundColor: $store.state.buttonColor }"
          :disabled="this.isValid() === false"
        >
          <Spinner v-if="status.isLoading" />
          <span v-else>Save password</span>
        </button>
      </fieldset>
    </form>
  </div>
</template>

<script>
import querystring from 'querystring';
import PasswordStrength from '@/components/PasswordStrength';

export default {
  name: 'ResetPassword',
  components: {
    PasswordStrength,
  },
  data: () => ({
    formData: {
      userEmail: '',
      code: '',
      newPassword: '',
      returnURl: '',
    },
    status: {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    },
    valid: false,
    passwordType: 'password',
    fillColor: 'var(--text-primary)',
  }),
  computed: {
    urlQuery() {
      const search = (window.location.search || '').replace(/^\?/, '');
      return querystring.decode(search);
    },
  },
  created() {
    //?plugin-rx-action=resetPassword&email=example@email.com&code=abcdefghijklmnop
    if (this.urlQuery['email'] && this.urlQuery['code']) {
      this.formData.userEmail = this.urlQuery['email'];
      this.formData.code = this.urlQuery['code'];
      this.formData.returnUrl = window.location.href;
    } else this.goHome();
  },
  methods: {
    isPasswordValid(valid) {
      this.valid = valid;
    },
    isValid() {
      try {
        return (
          this.valid &&
          this.formData.name !== '' &&
          this.formData.email !== '' &&
          this.formData.newPassword !== ''
        );
      } catch {
        return false;
      }
    },
    toggle() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.fillColor =
        this.passwordType === 'text' ? this.$store.state.buttonColor : 'var(--text-primary)';
    },
    async submit() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        const formData = new FormData();
        formData.append('userEmail', this.formData.userEmail);
        formData.append('code', this.formData.code);
        formData.append('newPassword', this.formData.newPassword);
        formData.append('returnUrl', this.formData.returnUrl);

        const data = await this.$request({
          url: '/account/setNewPassword',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data.succeeded) {
          this.status.successMessage = 'You have successfully updated your password.';
          if (data.returnUrl !== undefined) {
            window.location.href = data.returnUrl;
          } else {
            this.goHome();
          }
        } else {
          this.status.isLoading = false;
          throw new Error('Reset password was not successful.');
        }
      } catch (e) {
        this.status.errorMessage = e.message;
        this.status.isLoading = false;
      } finally {
        //this.status.isLoading = false;
      }
    },
    goHome() {
      const query = JSON.parse(JSON.stringify(this.urlQuery));
      delete query['plugin-rx-action'];
      delete query['email'];
      delete query['code'];
      window.history.replaceState(undefined, undefined, `?${querystring.encode(query)}`);
      this.$store.commit('ovNavigation/navigate', 'login');
      // this.$store.dispatch('userProfile/loadProfile', {vm: this});
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  flex-direction: column;
  .back-arrow {
    order: -1;
    align-self: self-start;
  }
}
</style>
