<template>
  <div class="d-flex flex-column _slot-wrapper">
    <div class="w-100 text-center" style="max-width: 337px">
      <div class="mb-3">
        <h2 class="mb-1" style="font-size: 32px; font-weight: 500">Verify email address</h2>
        <p class="my-0 diana-theme-text-secondary">
          To complete setting up your Rise-X account, please click the verification link we’ve sent
          to:
        </p>
        <p class="my-0 diana-theme-text-secondary">{{ this.$store.state.verifyEmail.email }}</p>
      </div>
      <div
        class="mb-3"
        style="
          border-radius: 16px;
          background-color: #f8f8f8;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 16px;
        "
      >
        <p class="mb-0">Didn’t get the email?</p>
        <p class="mb-0">
          <span v-if="resendCountdown > 0"
            >Check your spam folder or try resending it in <br />
            {{ resendCountdown }} seconds.</span
          >
          <span v-else>
            Check your spam folder or
            <a
              tabindex="1"
              class="accessibility-link"
              @click.prevent="handleResend"
              :style="{
                color: $store.state.buttonColor,
                cursor: 'pointer',
                textDecoration: 'underline',
                padding: 0,
              }"
              >Resend</a
            >
          </span>
        </p>
      </div>
      <!-- error text -->
      <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />

      <div v-if="$store.state.verifyEmail.retryUrl" class="w-100">
        <div class="d-inline-flex">
          <a
            href="#"
            class="diana-theme-text-primary"
            @click.prevent="$store.commit('ovNavigation/navigate', 'login')"
            >Back to login</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import querystring from 'querystring';
import ErrorMessage from '@/components/ErorrMessage.vue';

export default {
  name: 'VerifyEmail',
  components: { ErrorMessage },
  data: () => ({
    isLoggingOut: false,
    status: {
      isLoading: false,
      errorMessage: '',
    },
    resendCountdown: 0,
    timerId: null,
  }),
  methods: {
    async logout() {
      if (this.isLoggingOut) return;
      try {
        this.isLoggingOut = true;
        await this.$request({
          url: '/Account/SignOut',
          method: 'GET',
        });
      } catch (e) {
        console.error('Logout Error', e);
      } finally {
        this.isLoggingOut = false;
        this.$store.dispatch('userProfile/loadProfile', { vm: this });
      }
    },
    async resendEmailVerification() {
      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';

        await this.$request({
          url: this.$store.state.verifyEmail.retryUrl,
          method: 'GET',
        });
      } catch (e) {
        this.status.errorMessage = e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    handleResend() {
      this.resendEmailVerification();
      this.startResendCountdown(60);
    },
    startResendCountdown(seconds) {
      this.resendCountdown = seconds;

      if (this.timerId) {
        clearInterval(this.timerId);
      }

      this.timerId = setInterval(() => {
        if (this.resendCountdown > 0) {
          this.resendCountdown--;
        } else {
          clearInterval(this.timerId);
          this.timerId = null;
        }
      }, 1000);
    },
    openHelpScreen() {
      // Small delay so users know what is happening
      setTimeout(() => {
        window.open(this.$store.state.helpUrl, '_blank', 'noopener noreferrer');
        this.clearMessages();
      }, 500);
    },
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
};
</script>

<style scoped></style>
