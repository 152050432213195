<template>
  <div class="position-relative _slot-wrapper">
    <!-- login logo -->
    <div class="text-center mb-4">
      <h2 v-if="" class="mb-1" style="font-size: 32px; font-weight: 500">Sign in</h2>
      <span class="_welcome-subtitle mb-0 fs-2 fw-300 diana-theme-text-secondary"
        >Continue to your ecosystem</span
      >
    </div>

    <!-- login options -->
    <div class="_sso-wrapper mb-4">
      <button
        ref="firstElement"
        tabindex="1"
        type="button"
        class="_sso-button"
        @click.prevent="loginWithMicrosoft"
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.9165 9.16797H3.24984L3.24984 2.5013L9.9165 2.5013V9.16797Z" fill="#FF5722" />
          <path d="M18.25 9.16797H11.5833V2.5013L18.25 2.5013V9.16797Z" fill="#4CAF50" />
          <path d="M18.25 17.5H11.5833V10.8333H18.25V17.5Z" fill="#FFC107" />
          <path d="M9.9165 17.5H3.24984L3.24984 10.8333H9.9165V17.5Z" fill="#03A9F4" />
        </svg>
        <span class="align-middle ml-1">Microsoft</span>
      </button>

      <button tabindex="2" type="button" class="_sso-button" @click.prevent="loginWithGoogle">
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1286_17721"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="21"
            height="20"
          >
            <rect x="0.25" width="20" height="20" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_1286_17721)">
            <path
              d="M17.9305 10.1785C17.9305 9.52074 17.8771 9.04074 17.7616 8.54297H10.2505V11.5118H14.6594C14.5705 12.2496 14.0905 13.3608 13.0238 14.1074L13.0089 14.2068L15.3837 16.0466L15.5483 16.063C17.0594 14.6674 17.9305 12.6141 17.9305 10.1785Z"
              fill="#4285F4"
            />
            <path
              d="M10.2502 17.9998C12.4102 17.9998 14.2235 17.2887 15.548 16.062L13.0235 14.1064C12.348 14.5776 11.4413 14.9064 10.2502 14.9064C8.13467 14.9064 6.33913 13.5109 5.69906 11.582L5.60525 11.59L3.13581 13.5011L3.10352 13.5909C4.41906 16.2042 7.1213 17.9998 10.2502 17.9998Z"
              fill="#34A853"
            />
            <path
              d="M5.69886 11.5834C5.52998 11.0856 5.43224 10.5522 5.43224 10.0012C5.43224 9.45001 5.52998 8.9167 5.68998 8.41893L5.68551 8.31291L3.18512 6.37109L3.10332 6.41001C2.56112 7.49447 2.25 8.71227 2.25 10.0012C2.25 11.2901 2.56112 12.5078 3.10332 13.5923L5.69886 11.5834Z"
              fill="#FBBC05"
            />
            <path
              d="M10.2502 5.09332C11.7524 5.09332 12.7657 5.74221 13.3435 6.28447L15.6013 4.08001C14.2147 2.79112 12.4102 2 10.2502 2C7.1213 2 4.41906 3.79555 3.10352 6.40887L5.69018 8.41779C6.33913 6.4889 8.13467 5.09332 10.2502 5.09332Z"
              fill="#EB4335"
            />
          </g>
        </svg>
        <span class="align-middle ml-1">Google</span>
      </button>
    </div>

    <!-- login form -->
    <form @submit.prevent="submit">
      <fieldset :disabled="status.isLoading">
        <p
          v-if="status.successMessage"
          v-text="status.successMessage"
          :style="{ color: $store.state.buttonColor }"
          class="mb-3"
        />

        <!-- email input -->
        <div class="position-relative">
          <input
            tabindex="3"
            v-model="formData.Email"
            type="email"
            autocomplete="email"
            autocapitalize="none"
            autocorrect="off"
            spellcheck="off"
            class="_input input-lg mb-0 text-left accessibility-element"
            required="required"
            placeholder="Email address"
            :disabled="status.isSsoChecked"
          />
          <button
            v-show="status.isSsoChecked"
            class="btn end-icon"
            type="button"
            style="margin-right: 3px"
            @click.prevent="clearEmail()"
          >
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9734 2.20726L9.14007 0.373972C8.9008 0.149225 8.58727 0.0202686 8.25911 0.0116329C7.93095 0.00299726 7.61107 0.115285 7.36031 0.327137L1.33858 6.34887C1.12231 6.56697 0.98765 6.85282 0.957204 7.15846L0.669499 9.94853C0.660486 10.0465 0.673202 10.1453 0.706741 10.2378C0.74028 10.3303 0.793816 10.4143 0.863533 10.4838C0.926052 10.5458 1.0002 10.5949 1.08172 10.6282C1.16324 10.6615 1.25052 10.6783 1.33858 10.6778H1.3988L4.18887 10.4236C4.4945 10.3931 4.78036 10.2585 4.99846 10.0422L11.0202 4.02047C11.2539 3.77355 11.3802 3.44406 11.3714 3.10418C11.3627 2.76431 11.2195 2.44177 10.9734 2.20726ZM4.06843 9.08541L2.06119 9.27275L2.24184 7.26551L6.02215 3.53204L7.82867 5.33856L4.06843 9.08541ZM8.69847 4.44199L6.90534 2.64885L8.21005 1.31069L10.0366 3.13728L8.69847 4.44199Z"
                fill="var(--text-secondary)"
                style="transition: 0.2s"
              />
            </svg>
          </button>
        </div>

        <!-- password input -->
        <div class="position-relative" v-if="status.isSsoChecked">
          <input
            tabindex="4"
            v-model="formData.Password"
            :type="this.passwordType"
            autofocus="autofocus"
            autocomplete="off"
            autocapitalize="none"
            autocorrect="off"
            class="_input input-lg mt-3 mb-0 text-left accessibility-element"
            required="required"
            placeholder="Password"
          />
          <button type="button" class="btn end-icon" @click="toggle">
            <svg
              width="18"
              height="14"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z"
                :fill="fillColor"
                style="transition: 0.2s"
              />
            </svg>
          </button>
        </div>

        <div v-if="status.isSsoChecked" class="d-flex justify-content-end w-100 mt-2">
          <!-- forgot password text -->
          <a
            tabindex="5"
            href="#"
            class="diana-theme-text-primary accessibility-link"
            @click.prevent="$store.commit('ovNavigation/navigate', 'forgotPassword')"
            >Forgot password?</a
          >
        </div>

        <!-- error text -->
        <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="my-3" />

        <!-- login button -->
        <button
          :tabindex="formData.Password.length === 0 ? -1 : 6"
          v-if="status.isSsoChecked"
          type="submit"
          class="btn-lg btn-primary w-100 mb-3 mt-4 accessibility-element"
          :style="{ backgroundColor: $store.state.buttonColor, borderRadius: '6px' }"
          :disabled="formData.Password.length === 0"
        >
          <Spinner v-if="status.isLoading" />
          <span v-else>Login</span>
        </button>

        <!-- continue with email button -->
        <button
          v-else
          :tabindex="formData.Email.length === 0 ? -1 : 6"
          type="submit"
          class="btn-lg btn-primary w-100 mb-3 mt-4 accessibility-element"
          :style="{ backgroundColor: $store.state.buttonColor }"
          :disabled="formData.Email.length === 0"
          @click.prevent="checkSso"
        >
          <Spinner v-if="status.isLoading" />
          <span v-else>Continue</span>
        </button>
      </fieldset>
    </form>

    <!-- footer text -->
    <p class="text-center diana-theme-text-secondary mb-0">
      Don’t have an account?
      <a
        tabindex="7"
        href=""
        class="diana-theme-text-primary accessibility-link"
        @click.prevent="navigateToRegister"
        @blur="$emit('last-element-blur', $refs.firstElement)"
        >Register
      </a>
    </p>
  </div>
</template>

<script>
import querystring from 'querystring';
import { object, string } from 'yup';
import OrDivider from '../../components/OrDivider.vue';
import ErrorMessage from '@/components/ErorrMessage.vue';

const validateSchema = object({
  Email: string().required('Email address is required.').email('Invalid email address.'),
});

export default {
  data: () => ({
    formData: {
      Email: '',
      Password: '',
      RedirectUrl: window.location.href,
    },
    status: {
      errorMessage: '',
      successMessage: '',
      isSsoChecked: false,
      isLoading: false,
    },
    passwordType: 'password',
    fillColor: 'var(--text-secondary)',
  }),
  methods: {
    async validateEmail() {
      try {
        const result = await validateSchema.validate(
          { ...this.formData },
          {
            abortEarly: false,
          },
        );
        this.status.errorMessage = '';
        return result;
      } catch (err) {
        this.status.errorMessage = err.message;
        return false;
      }
    },
    toggle() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.fillColor = this.passwordType === 'text' ? '#23CF4E' : 'var(--text-secondary)';
    },
    clearMessages() {
      this.status.errorMessage = '';
      this.status.successMessage = '';
    },
    clearEmail() {
      this.formData.Email = '';
      this.status.isSsoChecked = false;
      this.clearMessages();
    },
    async submit() {
      if (!(await this.validateEmail())) {
        this.status.isLoading = false;
        return;
      }
      try {
        this.clearMessages();
        this.status.isLoading = true;
        this.$store.state.loginRedirectUrl = '';

        const formData = new FormData();
        formData.append('Email', this.formData.Email);
        formData.append('Password', this.formData.Password);
        formData.append('RedirectUrl', this.formData.RedirectUrl);

        const data = await this.$request({
          url: '/account/externalLogin',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data) {
          this.$store.state.loginRedirectUrl = data;
        }

        if (this.$store.state.loginRedirectUrl) {
          window.location.href = this.$store.state.loginRedirectUrl;
        }

        await this.$store.dispatch('userProfile/loadProfile', { vm: this });
      } catch (e) {
        this.status.errorMessage =
          e.xHR && e.xHR.responseJSON && !e.xHR.responseJSON.login
            ? 'Invalid email or password.'
            : e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    async checkSso() {
      if (!(await this.validateEmail())) {
        return;
      }
      try {
        this.clearMessages();
        this.status.isLoading = true;

        const formData = new FormData();
        formData.append('Email', this.formData.Email);

        const data = await this.$request({
          url: '/account/checkSso',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        // If the user is registered as an 'aad' or 'google' user they should be redirected.
        // Note that due to the architecture design, a user may have one or more logins.
        // For example, a user might register with Microsoft, Google, and password login.
        // In this case, the database will return the first valid login method for the user.
        if (data && data.length > 0) {
          if (data === 'aad') {
            return this.loginWithMicrosoft();
          }
          if (data === 'google') {
            return this.loginWithGoogle();
          }
        }

        // Store the email in store state for prefilling 'forgot password' screen.
        this.$store.state.username = this.formData.Email;
        this.status.isSsoChecked = true;
      } catch (e) {
        this.status.errorMessage =
          e.xHR && e.xHR.responseJSON && !e.xHR.responseJSON.login
            ? 'Invalid email or password.'
            : e.message;
      } finally {
        this.status.isLoading = false;
      }
    },
    loginWith(name, provider) {
      this.status.isLoading = true;

      const query = {
        provider,
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };

      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    loginWithMicrosoft() {
      return this.loginWith('Microsoft', 'aad');
    },
    loginWithGoogle() {
      return this.loginWith('Google', 'Google');
    },
    appendAnonIdToUrl(url) {
      const currentUrl = new URL(window.location.href);
      const anonId = currentUrl.searchParams.get('anonId');

      if (!anonId) {
        return url;
      }

      const updatedUrl = new URL(url);
      updatedUrl.searchParams.set('anonId', anonId);

      return updatedUrl.href;
    },
    navigateToRegister() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);

      query['plugin-rx-action'] = 'register';

      const newQueryString = querystring.encode(query);

      const newURL = `${window.location.pathname}?${newQueryString}`;

      window.history.replaceState({}, '', newURL);
      this.$store.commit('ovNavigation/navigate', 'register');
    },
  },
  components: { ErrorMessage, OrDivider },
};
</script>
