<template>
  <div class="_overlay d-flex flex-column">
    <Header />
    <div class="_content-wrapper">
      <div class="_content" :style="cssOverlayVars">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import querystring from 'querystring';

export default {
  name: 'OverlayView',
  components: { Header },
  data: () => ({
    accessibilityColor: '#23CF4E',
  }),
  mounted() {
    this.accessibilityColor = this.$store.state.buttonColor;
  },
  computed: {
    cssOverlayVars() {
      return {
        '--accessibility-color': `${this.accessibilityColor}35`, //35 is a transparency %
      };
    },
  },
  methods: {
    isRegisterPage() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);
      if (query['plugin-rx-action'] === 'register') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
._privacy-text {
  color: var(--text-secondary);
  padding: 48px 0;
  @media (min-width: 600px) {
    padding: 64px 0;
  }
}

._terms-link {
  text-decoration: underline;
  color: var(--text-secondary) !important;
}

._overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--background-paper);
  z-index: $zindex-modal-backdrop;
  color: var(--text-primary);
  overflow: auto;
}

._content-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  @media (min-width: 600px) {
    padding-top: 90px;
  }
}

._slot-wrapper {
  width: 100%;
  @media (min-width: 600px) {
    max-width: 400px;
  }
}

._sso-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

._sso-button {
  border-radius: 8px !important;
  border: 1px solid var(--divider);
  border-width: 1px !important;
  background-color: var(--background-paper);
  width: 100%;
  box-shadow: 0 1px 4px 0 #0000000d;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0000000d;
  }
}

._input {
  border-radius: 6px !important;
  transition: border-color 0.2s;
}

._content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--background-paper);
  border-radius: $border-radius;
  max-height: 100%;
  padding: 0 24px;

  input.accessibility-element {
    &:focus {
      caret-color: v-bind(accessibilityColor);
      border-color: v-bind(accessibilityColor);
      box-shadow: none;
    }
    &:disabled {
      background-color: transparent !important;
    }
  }

  .accessibility-element {
    &:focus {
      outline: none;
      //box-shadow: 0 0 0 4px var(--accessibility-color), 0 1px 2px 0 #2f33360d;
    }
    &:disabled {
      background-color: #0000001f !important;
    }
  }

  .accessibility-link {
    @extend .accessibility-element;
    padding: 4px;
    border-radius: 2px;
  }
}
</style>
