var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative _slot-wrapper" }, [
    _c(
      "div",
      { staticClass: "form-container", staticStyle: { "max-width": "337px" } },
      [
        _vm.status.isSuccess && !_vm.isSendLinkView
          ? _c("div", [
              _c("div", { staticClass: "mb-3 text-center" }, [
                _c(
                  "h2",
                  {
                    staticClass: "mb-1",
                    staticStyle: { "font-size": "32px", "font-weight": "500" },
                  },
                  [_vm._v("Recovery link sent")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "my-0 diana-theme-text-secondary" }, [
                  _vm._v(
                    "\n          To complete setting up your new password, please click the verification link we’ve sent\n          to:\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "my-0 diana-theme-text-secondary" }, [
                  _vm._v(_vm._s(_vm.$store.state.username)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mb-3",
                  staticStyle: {
                    "border-radius": "16px",
                    "background-color": "#f8f8f8",
                    width: "100%",
                    display: "flex",
                    "flex-direction": "column",
                    "justify-content": "center",
                    "text-align": "center",
                    padding: "16px",
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Didn’t get the email?"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm.resendCountdown > 0
                      ? _c("span", [
                          _vm._v(
                            "Check your spam folder or try resending it in "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.resendCountdown) +
                              " seconds."
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n            Check your spam folder or\n            "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "accessibility-link",
                              style: {
                                color: _vm.$store.state.buttonColor,
                                cursor: "pointer",
                                textDecoration: "underline",
                                padding: 0,
                              },
                              attrs: { tabindex: "1" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleResend.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Resend")]
                          ),
                        ]),
                  ]),
                ]
              ),
            ])
          : _c("div", [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "fieldset",
                    { attrs: { disabled: _vm.status.isLoading } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$store.state.username,
                            expression: "$store.state.username",
                          },
                        ],
                        ref: "firstElement",
                        staticClass:
                          "_input input-lg mb-3 accessibility-element",
                        attrs: {
                          tabindex: "1",
                          placeholder: "Email",
                          disabled: "",
                        },
                        domProps: { value: _vm.$store.state.username },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$store.state,
                              "username",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.status.errorMessage
                        ? _c("ErrorMessage", {
                            staticClass: "mb-3",
                            model: {
                              value: _vm.status.errorMessage,
                              callback: function ($$v) {
                                _vm.$set(_vm.status, "errorMessage", $$v)
                              },
                              expression: "status.errorMessage",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn-lg btn-primary w-100 mt-2 mb-3 accessibility-element",
                          style: {
                            backgroundColor: _vm.$store.state.buttonColor,
                            borderRadius: "6px",
                          },
                          attrs: { tabindex: "2", type: "submit" },
                        },
                        [
                          _vm.status.isLoading
                            ? _c("Spinner")
                            : _c("span", [_vm._v("Send a link")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "diana-theme-text-primary accessibility-link",
                            attrs: { tabindex: "3", href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$store.commit(
                                  "ovNavigation/navigate",
                                  "login"
                                )
                              },
                            },
                          },
                          [_vm._v("Back to login")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-4" }, [
      _c(
        "h2",
        {
          staticClass: "mb-1",
          staticStyle: { "font-size": "32px", "font-weight": "500" },
        },
        [_vm._v("Forgot password?")]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "_welcome-subtitle mb-0 diana-theme-text-secondary" },
        [_vm._v("We’ll send a recovery link to")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }